import { SET_CATEGORY } from '../types.const'

const initialState = {
    idCategory: 0 as number
}

export default function categoryReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_CATEGORY:
            return { ...state, idCategory: action.payload }
        default:
            return { ...state }
    }
}