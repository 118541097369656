import './Product.css';
import { Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AddItemToCart, RemoveItemFromCart, GetExistingItemFromCart } from '../../manager/cart.manager';
import { ProductDto } from '../../models/dataTransferObjects/product.dto';
import { AppState } from '../../redux/store';
import { CurrencyFormat } from "../../utils/currencyFormat";
import { useNavigate } from 'react-router-dom';

const Product = (productDto: ProductDto) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { itemList } = useSelector(
    (store: AppState) => store.cart
  )
  const item = GetExistingItemFromCart(productDto, itemList);
  const cantidad = item ? item.cantidad : 0;

  const addToCart = async () => {
    await dispatch(AddItemToCart(productDto))
  }
  const removeFromCart = async () => {
    await dispatch(RemoveItemFromCart(productDto))
  }

  // # Product Modal
  //const [showProductModal, setShowModal] = useState(false);
  const handleShow = (id: number) => {
    //setShowModal(true)
    navigate(`/producto/${id}`);
  };
  // # Product Modal

  return (
    <>
      <Card border="light" className="text-center product-container">
        <div className="div-img-product">
          <Card.Img className="img-product" variant="top" src={productDto.imagen} onClick={() => { handleShow(productDto.id); }} />
        </div>
        <Card.Body>
          <p  className="text-name">{productDto.nombre}</p>
          <p className="text-price">{CurrencyFormat(productDto.precio)}</p>
        </Card.Body>
        {
          cantidad === 0 ?
            <Button variant="custom" onClick={addToCart}>Agregar al carrito</Button>
            : <div>
              <Button variant="custom size-btn-add" onClick={removeFromCart}>-</Button>
              <span>{cantidad} un.</span>
              <Button variant="custom size-btn-add" onClick={addToCart}>+</Button>
            </div>
        }
      </Card>
      {/* <ProductModal showModal={showProductModal} setShowModal={setShowModal} product={productDto} ></ProductModal> */}
    </>
  );
}

export default Product;
