import { Button, Modal, Alert  } from 'react-bootstrap';
import './contact.css';

const ContactModal = (props: any) => {

    const handleClose = () => props.setShowModal(false);

    return (
        <>
            {
                <Modal size="lg" show={props.showModal} onHide={handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img
                                alt="Empresa"
                                src='https://storage.googleapis.com/simplepago-staging.appspot.com/kchilupipets_prueba/dog-phone.png'
                                height="250px"
                                className="p-2 bd-highlight imagen-contact"
                            />
                        </div>
                        <Alert variant="light">
                            <Alert.Heading className="text-center">¡Contáctanos!</Alert.Heading>
                            <p className="text-center">
                            Si necesitas contactarte con nosotros, puedes encontrarnos en los siguientes canales:
                            </p>
                        </Alert>
                        <div className="social">
                            <a href="https://wa.link/zfnq6u"><img src="https://kchilupipets.cl/img/whatsapp.png" className="icono" alt="whatsapp" /></a>
                            <a href="tel:+56936636481"><img src="https://kchilupipets.cl/img/telefono.png" className="icono" alt="telefono" /></a>
                            <a href="https://www.facebook.com/kchilupipet"><img src="https://kchilupipets.cl/img/facebook.png" className="icono" alt="facebook" /></a>
                            <a href="https://www.instagram.com/kchilupipets/"><img src="https://kchilupipets.cl/img/instagram.png" className="icono" alt="instagram" /></a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default ContactModal;