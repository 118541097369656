export class PaginationDto {
    public actualPage: number;
    public perPage: number;
    public totalRows: number;
    public previus: number;
    public next: number;

    constructor(actualPage: number, perPage: number, totalRows: number, previus: number, next: number) {
        this.actualPage = actualPage;
        this.perPage = perPage;
        this.totalRows = totalRows;
        this.previus = previus;
        this.next = next;
    }
}