import { ItemMapper } from '../mapper/item.mapper';
import { ItemDto } from '../models/dataTransferObjects/item.dto'
import { ProductDto } from '../models/dataTransferObjects/product.dto'
import { AddToCart, RemoveFromCart, EmptyCartAction } from '../redux/actions/cart.action';

export const AddItemToCart = (productDto: ProductDto) => async (dispatch: any, getState: any) => {
    let itemList: ItemDto[] = getState().cart.itemList;
    const item = GetExistingItemFromCart(productDto, itemList);
    if (!item) {
        itemList.push(ItemMapper.toDto(productDto))
    }
    else {
        itemList = IncreaseQuantityItem(productDto, itemList)
    }

    dispatch(AddToCart(itemList))
}

export const RemoveItemFromCart = (productDto: ProductDto) => async (dispatch: any, getState: any) => {
    let itemList: ItemDto[] = getState().cart.itemList;
    const item = GetExistingItemFromCart(productDto, itemList);
    if (item) {
        itemList = DecreaseQuantityItem(productDto, itemList)
    }
    dispatch(RemoveFromCart(itemList))
}

export const EmptyCart = () => async (dispatch: any, getState: any) => {
    dispatch(EmptyCartAction());
}

/*
export const GetItemFromCart = (productDto:ProductDto) => async (dispatch:any, getState:any) => {
    const {itemList} = getState().cart;
    
    itemList.forEach((item: ItemDto) => {
        if(item.producto.id === productDto.id){
            return item;
        }
    });
}
*/
export const GetExistingItemFromCart = (productDto: ProductDto, itemList: ItemDto[]): ItemDto | null => {
    if (itemList.length === 0) {
        return null
    }

    const filterArray = itemList.filter(item =>
        item.producto.id === productDto.id
    )
    if (filterArray.length === 0) {
        return null
    }

    let itemA = null;
    itemList.forEach(item => {
        if (item.producto.id === productDto.id) {
            itemA = item
        }
    })
    return itemA;
}

const IncreaseQuantityItem = (productDto: ProductDto, itemList: ItemDto[]): ItemDto[] => {
    itemList.forEach(item => {
        if (item.producto.id === productDto.id) {
            item.cantidad++
        }
    })
    return itemList
}

const DecreaseQuantityItem = (productDto: ProductDto, itemList: ItemDto[]): ItemDto[] => {
    const itemListNew: ItemDto[] = []
    itemList.forEach(item => {
        if (item.producto.id === productDto.id) {
            item.cantidad > 1 ? item.cantidad-- : item.cantidad = 0
            if (item.cantidad === 0) {
                return
            }
        }
        itemListNew.push(item);
    })
    return itemListNew
}

