import './Banner.css';
import { Carousel } from 'react-bootstrap';

function Banner(props: any) {
  return (
    <>
      <div className="img-banner">
        <div className="slide__image">
          {/* <img src={props.design.urlBanner} className="img-size" alt="banner"/> */}

          {props.design.urlsBanner ?
            <Carousel >

              {
                props.design.urlsBanner.map((item: any, index: any) =>
                  <Carousel.Item key={index}>
                    <img
                      className="d-block img-size"
                      src={item.url}
                      alt="First slide"
                    />
                  </Carousel.Item>
                )
              }
            </Carousel>
            : ""
          }
        </div>
      </div>
    </>
  );
}

export default Banner;
