import { Button, Col, Container, Modal, Row, Table, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { DeliveryInfoDto } from '../../../models/dataTransferObjects/deliveryInfo.dto';
import { ItemDto } from '../../../models/dataTransferObjects/item.dto';
import { AppState } from '../../../redux/store';
import { CurrencyFormat } from '../../../utils/currencyFormat';
import PaymentModal from '../payment/payment.modal';
import { ProductService } from '../../../services/product.service';
import { SetOrder } from '../../../redux/actions/order.action';
import './summary.css';

const SummaryModal = (props: any) => {
  const delivery: DeliveryInfoDto = props.delivery;
  const [showModal, setShowModal] = useState(false);
  const [shipping, setShipping] = useState(0);
  const handleShow = () => setShowModal(true);
  const dispatch = useDispatch();

  const { itemList } = useSelector(
    (store: AppState) => store.cart
  )
  const totalPrice = itemList.reduce(function (previous: any, current: ItemDto) {
    return previous + (current.cantidad * current.producto.precio);
  }, 0);

  const handleClose = () => props.setShowModal(false);
  const { handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    createOrder();
    handleClose();
    handleShow();
  }

  const createOrder = async () => {
    const productService = new ProductService();
    const idStore = (document.getElementById("id-tienda") as HTMLInputElement).value;
    const returnUrl = window.location.protocol + "//" + window.location.host + "/pago/";
    console.log('return url: ', returnUrl);
    await dispatch(SetOrder(await productService.CreateOrder(idStore, itemList, delivery, returnUrl)));
  }

  const shippingCost = async () => {
    if (delivery.communeId > 0) {
      const productService = new ProductService();
      let idStore = (document.getElementById("id-tienda") as HTMLInputElement).value;
      setShipping(await productService.GetShippingCost(idStore, delivery.communeId));
    }
  }
  shippingCost();

  return (
    <>
      {
        <Modal
          show={props.showModal}
          onHide={handleClose}
          size="lg"
          backdrop="static"
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Resumen del pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="justify-content-md-center"><h5>Productos</h5></Row>
              </Container>
              <Table responsive>
                <thead>
                  <tr className="d-flex">
                    <th className="col-2"></th>
                    <th className="col-4">Producto</th>
                    <th className="col-3">Cantidad</th>
                    <th className="col-3">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    itemList.map((item: ItemDto, index: number) => (
                      <tr key={index} className="d-flex">
                        <td className="col-2">
                          <img className="img" alt="img-resumen" src={item.producto.imagen} />
                        </td>
                        <td className="col-4">{item.producto.nombre}</td>
                        <td className="col-3 text-center">{item.cantidad} un.</td>
                        <td className="col-3">{CurrencyFormat(item.producto.precio)}</td>
                      </tr>
                    ))
                  }
                  <tr className="d-flex">
                    <td className="col-2">
                      <img className="img" src="https://storage.googleapis.com/simplepago-staging.appspot.com/iconos/delivery.png" alt="transporte" />
                    </td>
                    <td className="col-4">Transporte</td>
                    <td className="col-3 text-center">1 un.</td>
                    <td className="col-3">{shipping > 0 ? CurrencyFormat(shipping) : "¡Gratis!"}</td>
                  </tr>
                  <tr className="d-flex">
                    <td className="col-3"></td>
                    <td className="col-3"></td>
                    <td className="col-3 text-center">
                      <b>Total</b>
                    </td>
                    <td className="col-3">
                      <span><b>{CurrencyFormat(totalPrice + shipping)}</b></span>
                    </td>
                  </tr>
                </tbody>
              </Table>



              <Container>
                <Row className="justify-content-md-center">
                  <h5>Dirección de despacho</h5>
                </Row>
                <Row>
                  <Col xs lg="2"></Col>
                  <Col xs lg="8">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>Nombre</td>
                          <td>{delivery.name}</td>
                        </tr>
                        <tr>
                          <td>Correo Electrónico</td>
                          <td>{delivery.email}</td>
                        </tr>
                        <tr>
                          <td>Teléfono</td>
                          <td>{delivery.phone}</td>
                        </tr>
                        <tr>
                          <td>Región</td>
                          <td>{delivery.region}</td>
                        </tr>
                        <tr>
                          <td>Comuna</td>
                          <td>{delivery.commune}</td>
                        </tr>
                        <tr>
                          <td>Dirección</td>
                          <td>{delivery.address}</td>
                        </tr>
                        <tr>
                          <td><b>Tiempo de entrega</b></td>
                          <td><b>Entre 1 a 2 dias hábiles</b></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs lg="2"></Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button variant="success" type="submit">
                Confirmar y pagar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      }
      <PaymentModal showModal={showModal} setShowModal={setShowModal} totalPrice={totalPrice} ></PaymentModal>
    </>

  );
}

export default SummaryModal;