export class PaymentInformationDto {
    public businessName: string;
    public rut: number;
    public rutDv: string;
    public mail: string;
    public idBank: number;
    public idAccountType: number;
    public accountNumber: string;

    constructor(businessName: string, rut: number, rutDv: string, mail: string, idBank: number, idAccountType: number, accountNumber: string) {
        this.businessName = businessName;
        this.rut = rut;
        this.rutDv = rutDv;
        this.mail = mail;
        this.idBank = idBank;
        this.idAccountType = idAccountType;
        this.accountNumber = accountNumber;
    }
}