import { ProductDto } from '../models/dataTransferObjects/product.dto';

export class ProductMapper {
    public static toDto(operationResult: any): ProductDto[] {
        const productList = operationResult.resultado.map((product: any) => {
            const dto = new ProductDto(
                product.PK_Producto,
                product.UrlImagen,
                product.Nombre,
                product.Marca,
                product.Precio
            );
            return dto
        })
        return productList;
    }

    public static OnetoDto(operationResult: any): ProductDto {
        const res = operationResult?.resultado;
        return new ProductDto(
            res?.PK_Producto,
            res?.UrlImagen,
            res?.Nombre,
            res?.Marca,
            res?.Precio
        );
    }


}
