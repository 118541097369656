import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import generateStore from './redux/store';
import Store from './views/store.view';

const App = () => {  
  const store = generateStore();
  return (
    <Provider store={store}>
        <Store></Store>
    </Provider>
  );
}

export default App;