import { SET_ORDER } from '../types.const'

const initialState = {
    idOrder: 0 as number
}

export default function orderReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ORDER:
            return { ...state, idOrder: action.payload }
        default:
            return { ...state }
    }
}