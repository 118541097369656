import { ItemDto } from '../../models/dataTransferObjects/item.dto'
import { ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART} from '../types.const'

const initialState = {
    itemList: [] as ItemDto[]
}

export default function cartReducer(state = initialState, action:any) {
    switch(action.type){
        case ADD_TO_CART:
            return {...state, itemList: action.payload}
        case REMOVE_FROM_CART:
            return {...state, itemList: action.payload}
        case EMPTY_CART:
            return {...state, itemList: action.payload}
        default:
            return {...state}
    }
}