// import { ProductMapper } from '../mapper/product.mapper';
// import { ProductDto } from '../models/dataTransferObjects/product.dto';

export class AddressService {
    public async GetRegions(): Promise<any> {
        const data = await fetch('https://api-parameter-dot-simplepago-staging.uc.r.appspot.com/api/v1/region/0/1')
        const regions = await data.json()
        return regions.resultado;
    }

    public async GetCommunes(idRegion:number): Promise<any> {
        if(idRegion){
            const data = await fetch(`https://api-parameter-dot-simplepago-staging.uc.r.appspot.com/api/v1/comuna/0/${idRegion}`)
            const regions = await data.json()
            return regions.resultado;
        }
        else{
            return []
        }
    }
}
