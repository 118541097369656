import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./delivery.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { AddressService } from "../../../services/address.service";
import SummaryModal from "../summary/summary.modal";
import { DeliveryInfoDto } from "../../../models/dataTransferObjects/deliveryInfo.dto";
import { DeliveryInfoMapper } from "../../../mapper/deliveryInfo.mapper";
import { ProductService } from "../../../services/product.service";

const DeliveryModal = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [deliveryData, setDeliveryData] = useState<DeliveryInfoDto>(
    new DeliveryInfoDto("", "", "", "", "", 0, "", 0)
  );
  const handleShow = () => setShowModal(true);

  const [regionList, setregionList] = useState([]);
  const [communeList, setCommuneList] = useState([]);

  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+569");

  const handleClose = () => props.setShowModal(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data: any) => {
    data.DeliveryCommuneName = communeList.filter(
      (commune: any) => commune.PK_Comuna.toString() === data.DeliveryCommune
    );
    data.DeliveryRegionName = regionList.filter(
      (region: any) => region.PK_Region.toString() === data.DeliveryRegion
    );

    validateFields();

    if (validName && name && validEmail && email && validPhone && phone) {
      data.DeliveryName = name;
      data.DeliveryEmail = email;
      data.DeliveryPhone = phone;
      setDeliveryData(DeliveryInfoMapper.toDto(data));

      handleClose();
      handleShow();
    }
  };

  const validateFields = () => {
    if (!name) setValidName(false);
    if (!email) setValidEmail(false);
    if (!phone) setValidPhone(false);
  };

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = async () => {
    const addressService = new AddressService();
    setregionList(await addressService.GetRegions());
  };

  const getCommunes = async (e: any) => {
    const addressService = new AddressService();
    const productService = new ProductService();

    let idStore = (document.getElementById("id-tienda") as HTMLInputElement)
      .value;

    let communeTotal = await addressService.GetCommunes(e.target.value);
    let communeFilter = await productService.GetShippingCommune(idStore);

    setCommuneList(
      communeTotal.filter((commune: any) =>
        communeFilter.includes(commune.PK_Comuna)
      )
    );
  };

  const validateName = (evt: any) => {
    const { target } = evt;
    const { value } = target;
    let isValid = false;
    var regExp = /^(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
    setName(value);
    if (value.match(regExp)) {
      isValid = true;
    }
    setValidName(isValid);
  };

  const validateEmail = (evt: any) => {
    const { target } = evt;
    const { value } = target;
    let isValid = false;
    setEmail(value);
    var regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(regExp)) {
      isValid = true;
    }
    setValidEmail(isValid);
  };

  const validatePhone = (evt: any) => {
    const { target } = evt;
    let { value } = target;
    value = value.replace("+", "");
    let isValid = false;
    var regExp = /^\d+$/;
    if (value.match(regExp) && value.length === 11) {
      isValid = true;
    }
    if (!value || value === "5" || value === "56") value = "569";
    setPhone("+" + value);
    setValidPhone(isValid);
  };

  const isValidForm = (): boolean => {
    return (
      validName &&
      validEmail &&
      validPhone &&
      !errors.DeliveryAddress &&
      !errors.DeliveryRegion &&
      !errors.DeliveryCommune
    );
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} size="lg"  backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Información de despacho</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Nombre
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Ingresa tu nombre"
                  onChange={validateName}
                  value={name}
                  className={`${validName ? "" : "is-invalid"}`}
                />
                {validName ? null : (
                  <Form.Text className="text-danger">Campo inválido</Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Correo
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu email"
                  onChange={validateEmail}
                  value={email}
                  className={`${validEmail ? "" : "is-invalid"}`}
                />
                {validEmail ? null : (
                  <Form.Text className="text-danger">Campo inválido</Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Teléfono
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  id="phone"
                  placeholder="Ingresa tu teléfono"
                  value={phone}
                  maxLength={12}
                  onChange={validatePhone}
                  className={`${validPhone ? "" : "is-invalid"}`}
                />
                {validPhone ? null : (
                  <Form.Text className="text-danger">Campo inválido</Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="DeliveryAddress">
              <Form.Label column sm={2}>
                Dirección
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Ingresa tu dirección"
                  className={`${errors.DeliveryAddress ? "is-invalid" : ""}`}
                  {...register("DeliveryAddress", {
                    required: {
                      value: true,
                      message: "Campo requerido",
                    },
                    minLength: {
                      value: 5,
                      message: "Ingresa minimo 5 caracteres",
                    },
                  })}
                />
                {errors.DeliveryAddress && (
                  <Form.Text className="text-danger">
                    {errors.DeliveryAddress.message}
                  </Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="DeliveryRegion">
              <Form.Label column sm={2}>
                Región
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  defaultValue="Selecciona tu región"
                  className={`${
                    errors.DeliveryRegion ? "remove-alert-icon is-invalid" : ""
                  }`}
                  onChangeCapture={getCommunes}
                  {...register("DeliveryRegion", {
                    required: {
                      value: true,
                      message: "Campo requerido",
                    },
                    validate: (value) => value > 0,
                  })}
                >
                  <option value="">Selecciona tu región</option>
                  {regionList.map((item: any, index: number) => (
                    <option value={item.PK_Region} key={index}>
                      {item.NombreRegion}
                    </option>
                  ))}
                </Form.Control>
                {errors.DeliveryRegion && (
                  <Form.Text className="text-danger">
                    {errors.DeliveryRegion.message}
                  </Form.Text>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="DeliveryCommune">
              <Form.Label column sm={2}>
                Comuna
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  defaultValue="Selecciona tu comuna"
                  className={`${
                    errors.DeliveryCommune ? "remove-alert-icon is-invalid" : ""
                  }`}
                  {...register("DeliveryCommune", {
                    required: {
                      value: true,
                      message: "Campo requerido",
                    },
                    validate: (value) => value > 0,
                  })}
                >
                  <option value="">Selecciona tu comuna</option>
                  {communeList.map((item: any, index: number) => (
                    <option value={item.PK_Comuna}>{item.NombreComuna}</option>
                  ))}
                </Form.Control>
                {errors.DeliveryCommune && (
                  <Form.Text className="text-danger">
                    {errors.DeliveryCommune.message}
                  </Form.Text>
                )}
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button
              variant="success"
              type="submit"
              disabled={isValidForm() ? false : true}
            >
              Realizar Pedido
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <SummaryModal
        showModal={showModal}
        setShowModal={setShowModal}
        delivery={deliveryData}
      ></SummaryModal>
    </>
  );
};

export default DeliveryModal;
