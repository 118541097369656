import './Footer.css';

function Footer() {
  return (
    <div className="footer text-center">
      <a href="https://www.simplepago.cl" className="footer__powered">Copyright © 2024 KchilupiPets -  Todos los derechos reservados</a>
    </div>
  );
}

export default Footer;
