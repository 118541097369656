import { DeliveryInfoDto } from '../models/dataTransferObjects/deliveryInfo.dto';

export class DeliveryInfoMapper {
    public static toDto(formData: any): DeliveryInfoDto {
        let communeName = '';
        if(formData.DeliveryCommuneName && formData.DeliveryCommuneName[0] && formData.DeliveryCommuneName[0].NombreComuna){
            communeName = formData.DeliveryCommuneName[0].NombreComuna
        }
        else{
            console.error("error delivery info mapper");
        }

        let regionName = '';
        if(formData.DeliveryRegionName && formData.DeliveryRegionName[0] && formData.DeliveryRegionName[0].NombreRegion){
            regionName = formData.DeliveryRegionName[0].NombreRegion
        }
        else{
            console.error("error delivery info mapper");
        }

        const deliveryInfo = new DeliveryInfoDto(
            formData.DeliveryName,
            formData.DeliveryEmail,
            formData.DeliveryPhone,
            formData.DeliveryAddress,
            communeName,
            formData.DeliveryCommune,
            regionName,
            formData.DeliveryRegion,
        );
        return deliveryInfo;
    }
}
