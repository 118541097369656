import { ItemDto } from '../../models/dataTransferObjects/item.dto'
import { ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART } from '../types.const';

export const AddToCart = (itemList: ItemDto[]) => {
    return {
        type: ADD_TO_CART,
        payload: itemList
    };
};

export const RemoveFromCart = (itemList: ItemDto[]) => {
    return {
        type: REMOVE_FROM_CART,
        payload: itemList
    };
}

export const EmptyCartAction = () => {
    return {
        type: EMPTY_CART,
        payload: []
    };
}