import { BannerDto } from "./banner.dto";

export class DesignDto {
    public urlBanner: string;
    public nombreTienda: string;
    public logo: string;
    public mensajeBienvenida: string;
    public colorHeader: string;
    public urlsBanner: BannerDto[];

    constructor(urlBanner: string, nombreTienda: string, logo: string, mensajeBienvenida: string, colorHeader: string, urlsBanner: any) {
        this.urlBanner = urlBanner;
        this.nombreTienda = nombreTienda;
        this.logo = logo;
        this.mensajeBienvenida = mensajeBienvenida;
        this.colorHeader = colorHeader;
        this.urlsBanner = urlsBanner.map((item: any) => {
            return new BannerDto(item.Url)
        });
    }
}