export class DeliveryInfoDto {
    public name: string;
    public email: string;
    public phone: string;
    public address: string;
    public commune: string;
    public communeId: number;
    public region: string;
    public regionId: number;

    constructor
        (name: string, email: string, phone: string, address: string, 
        commune:string, communeId:number, region:string, regionId: number) {
            this.name = name;
            this.email = email;
            this.phone = phone;
            this.address = address;
            this.commune = commune;
            this.communeId = communeId;
            this.region = region
            this.regionId = regionId
    }
}