import { ProductDto } from "./product.dto";

export class ItemDto {
    public producto: ProductDto;
    public cantidad: number;

    constructor(producto: ProductDto, cantidad?:number) {
        this.producto = producto;
        this.cantidad = cantidad? cantidad : 0;
    }
}