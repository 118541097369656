import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import cartReducer from './reducers/cart.reducer';
import categoryReducer from './reducers/category.reducer';
import orderReducer from './reducers/order.reducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  category: categoryReducer,
  order: orderReducer
})

export type AppState = ReturnType<typeof rootReducer>

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
  return store
}