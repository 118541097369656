import { CategoryDto } from "../models/dataTransferObjects/category.dto";

export class CategoryMapper {
    public static toDto(operationResult: any): CategoryDto[] {
        const categoryLst = operationResult.resultado.map((category: any) => {
            const dto = new CategoryDto(
                category.PK_Categoria,
                category.Nombre
            );
            return dto;
        });
        return categoryLst;
    }
}