import { TransactionDto } from "../models/dataTransferObjects/transaction.dto";

export class TransactionMapper {
    public static toDto(transaction: any): TransactionDto {
        return new TransactionDto(
            transaction.amount,
            transaction.buyOrder,
            transaction.state,
            transaction.NombreCliente,
            transaction.EmailCliente,
            transaction.fecha,
            transaction.hora,
            transaction.Telefono,
            transaction.NombreRegion,
            transaction.NombreComuna,
            transaction.NombreDireccion);
    }
}