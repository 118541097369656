import { CategoryMapper } from '../mapper/category.mapper';
import { PaymentInformationMapper } from '../mapper/paymentInformation.mapper';
import { ProductMapper } from '../mapper/product.mapper';
import { ProductPaginationMapper } from '../mapper/productPagination.mapper';
import { ShippingMapper } from '../mapper/shipping.mapper';
import { ShippingCommuneMapper } from '../mapper/shippingCommune.mapper';
import { TransactionMapper } from '../mapper/transaction.mapper';
import { DeliveryInfoDto } from '../models/dataTransferObjects/deliveryInfo.dto';
import { DeliveryServiceDto } from '../models/dataTransferObjects/deliveryService.dto';
import { ItemDto } from '../models/dataTransferObjects/item.dto';
import { PaymentInformationDto } from '../models/dataTransferObjects/paymentInformation.dto';
import { ProductDto } from '../models/dataTransferObjects/product.dto';
import { ProductPaginationDto } from '../models/dataTransferObjects/productPagination.dto';
import { ProductServiceDto } from '../models/dataTransferObjects/productService.dto';
import { TransactionDto } from '../models/dataTransferObjects/transaction.dto';

export class ProductService {
    public async GetProducts(bussinessToken: string, idCategory: number, actualPage: number): Promise<ProductPaginationDto> {
        let category = idCategory === 0 ? '0' : idCategory;
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/producto/${bussinessToken}/${category}/${actualPage}`)
        const products = await data.json();
        return ProductPaginationMapper.toDto(products);
    }

    public async GetProductById(bussinessToken: string, idProduct: number): Promise<ProductDto> {
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/producto/${bussinessToken}/${idProduct}`)
        const products = await data.json();
        return ProductMapper.OnetoDto(products);

    }

    public async GetCategory(bussinessToken: string): Promise<any> {
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/categoria/${bussinessToken}`)
        const category = await data.json();
        return CategoryMapper.toDto(category);
    }

    public async CreateOrder(bussinessToken: string, productList: ItemDto[], delivery: DeliveryInfoDto, returnUrl: string): Promise<number> {

        let productos: ProductServiceDto[] = [];
        productList.forEach((item: ItemDto) => {
            productos.push(new ProductServiceDto(item.producto.id, item.cantidad))
        });

        let deliveryService: DeliveryServiceDto = new DeliveryServiceDto(
            delivery.regionId,
            delivery.communeId,
            delivery.region,
            delivery.commune,
            delivery.address,
            delivery.name,
            delivery.email,
            delivery.phone);

        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/pedido/${bussinessToken}`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ productos, despacho: deliveryService, returnUrl })
            })
        let respuesta = await data.json();
        return respuesta.resultado;
    }

    public async GetPaymentInformation(businessToken: string): Promise<PaymentInformationDto> {
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/informacion-pago/${businessToken}`)
        const paymentInformation = await data.json();
        return PaymentInformationMapper.toDto(paymentInformation);
    }

    public async GetShippingCost(businessToken: string, idCommune: number): Promise<number> {
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/costo-despacho/${businessToken}/${idCommune}`)
        const shippingCost = await data.json();
        return ShippingMapper.toDto(shippingCost);
    }

    public async GetShippingCommune(businessToken: string): Promise<number[]> {
        const data = await fetch(`https://api-producto-dot-simplepago-staging.uc.r.appspot.com/api/v1/comuna-despacho/${businessToken}`)
        const shippingCommune = await data.json();
        return ShippingCommuneMapper.toDto(shippingCommune);
    }

    public async GetTransaction(idTransaction: string): Promise<TransactionDto> {
        console.log(`se consultará información: https://simplepago-staging.uc.r.appspot.com/api/v1/transaccion/${idTransaction}`);
        const data = await fetch(`https://simplepago-staging.uc.r.appspot.com/api/v1/transaccion/${idTransaction}`)
        const transaction = await data.json();
        console.log('RESULTADO: ', transaction);
        return TransactionMapper.toDto(transaction);
    }
}
