import { PaginationDto } from "./pagination.dto";
import { ProductDto } from "./product.dto";


export class ProductPaginationDto{
    public productDto: ProductDto[];
    public paginationDto: PaginationDto;

    constructor(productDto: ProductDto[], paginationDto: PaginationDto){
        this.productDto = productDto;
        this.paginationDto = paginationDto;
    }
}