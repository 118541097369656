import { Button, Col, Container, Modal, Row, Table, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { AppState } from '../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { EmptyCart } from '../../../manager/cart.manager';
import { CurrencyFormat } from '../../../utils/currencyFormat';
import { ProductService } from '../../../services/product.service';
import { PaymentInformationDto } from '../../../models/dataTransferObjects/paymentInformation.dto';
import { BankService } from '../../../services/bank.service';
import { BankDto } from '../../../models/dataTransferObjects/bank.dto';
import { PulseLoader } from "react-spinners";

const PaymentModal = (props: any) => {
    const { idOrder } = useSelector(
        (store: AppState) => store.order
    )

    const dispatch = useDispatch();

    const handleClose = async () => {
        props.setShowModal(false);
        await dispatch(EmptyCart());
    }
    const { handleSubmit } = useForm();
    let totalPrice = 0;
    if (props.totalPrice > 0) {
        totalPrice = props.totalPrice;
    }

    const onSubmit = (data: any) => {
        handleClose();
  };

    useEffect(() => {
    redirect();
  }, [idOrder]);

  const redirect = () => {
    if (idOrder) {
      const url =
        "https://orqpay-dot-simplepago-staging.uc.r.appspot.com/v1/" +
        idOrder;
      window.location.replace(url);
    }
  };

    return (
        <>
            {
                <Modal
                    show={props.showModal}
                    onHide={handleClose}
                    size="lg"
                    backdrop="static"
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Forma de pago</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row className="justify-content-md-center">
                                  <h6>
                                    Gracias por su pedido. En unos segundos será redireccionado
                                    para completar su compra...
                                  </h6>                                  
                                </Row>
                                <div className="text-center mt-4 mb-4">
                                    <PulseLoader size={25} color={"#DABBB4"} />
                                  </div>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default PaymentModal;