export class DeliveryServiceDto {

    public idRegion: number;
    public idComuna: number;
    public region: string;
    public comuna: string;
    public direccion: string;
    public nombreCliente: string;
    public emailCliente: string;
    public telefonoCliente: string;

    constructor(idRegion: number, idComuna: number, region: string, comuna: string, direccion: string, nombreCliente: string, emailCliente: string, telefonoCliente: string) {
        this.idRegion = idRegion;
        this.idComuna = idComuna;
        this.region = region;
        this.comuna = comuna;
        this.direccion = direccion;
        this.nombreCliente = nombreCliente;
        this.emailCliente = emailCliente;
        this.telefonoCliente = telefonoCliente;
    }
}