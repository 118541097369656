import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AppState } from "../../../src/redux/store";
import { ProductDto } from "../../models/dataTransferObjects/product.dto";
// import Product from "../../components/product/Product";
import { ProductService } from "../../services/product.service";
import { CurrencyFormat } from "../../utils/currencyFormat";
import {
  AddItemToCart,
  RemoveItemFromCart,
  GetExistingItemFromCart,
} from "./../../manager/cart.manager";
import "./product-detail-view.css";

type ProductDetailState = {
  product: ProductDto | undefined;
  state: "loading" | "ready" | "error";
};
// const dispatch = useDispatch();

//const item = GetExistingItemFromCart(productDto, itemList);
// const cantidad = 0; //item ? item.cantidad : 0;

// const addToCart = async () => {
//   await dispatch(AddItemToCart(productDto));
// };
// const removeFromCart = async () => {
//   await dispatch(RemoveItemFromCart(productDto));
// };

const ProductDetail = (props: any) => {
  const dispatch = useDispatch();
  const { itemList } = useSelector((store: AppState) => store.cart);
  const { id } = useParams();
  const [state, setState] = useState<ProductDetailState>({
    product: undefined,
    state: "loading",
  });
  let idStore = (document.getElementById("id-tienda") as HTMLInputElement)
    .value;

  useEffect(() => {
    const productService = new ProductService();
    productService
      .GetProductById(idStore, +id!)
      .then((resp) => {
        setState({ product: resp, state: "ready" });
      })
      .catch((err) => {
        setState({ product: undefined, state: "error" });
      });
  }, []);

  if (state.state === "error") {
    return (
      <div className="mt-4 mb-4 text-center">
        <h3>El producto no fue encontrado en el catálogo</h3>
      </div>
    );
  }

  // const item = GetExistingItemFromCart(state.product, itemList);
  // const cantidad = item ? item.cantidad : 0;
  const product = new ProductDto(
    state.product?.id ?? 0,
    state.product?.imagen ?? "",
    state.product?.nombre ?? "",
    state.product?.marca ?? "",
    state.product?.precio ?? 0
  );
  const item = GetExistingItemFromCart(product, itemList);
  const cantidad = item ? item.cantidad : 0;
  const addToCart = async () => {
    if (state.product) {
      console.log("productDto: ", state.product);

      await dispatch(AddItemToCart(state.product));
    }
  };
  const removeFromCart = async () => {
    if (state.product) {
      await dispatch(RemoveItemFromCart(state.product));
    }
  };

  return (
    <div className="mt-4 mb-4">
      <br />
      {state.state === "loading" ? (
        <div className="loading">
          <PulseLoader size={50} color={"#DABBB4"} />
        </div>
      ) : null}

      <div className="container-product-detail">
        <div className="container-photo">
          <div className="box-photo">
            <img
              src={state.product?.imagen}
              alt={state.product?.nombre}
              className="product-photo"
            ></img>
          </div>
        </div>
        <div className="container-description">
          <p className="product-detail-brand">{state.product?.marca}</p>
          <p className="product-detail-title">{state.product?.nombre}</p>
          <p className="product-price">
            {CurrencyFormat(state.product?.precio || 0)}
          </p>
          <p className="product-short-description">Descripción del producto</p>
          {cantidad === 0 ? (
            <Button className="btn-add" onClick={() => addToCart()}>
              <p className="btn-text">Agregar al carrito</p>
            </Button>
          ) : (
            <div className="size-btn-box">
              <Button
                variant="custom size-btn-add"
                onClick={() => removeFromCart()}
              >
                -
              </Button>
              <span>{cantidad} un.</span>
              <Button variant="custom size-btn-add" onClick={() => addToCart()}>
                +
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* <Product {...state.product!} /> */}
      <br />
      <div className="text-center">
        <NavLink to="/">Volver</NavLink>
      </div>
    </div>
  );
};

export default ProductDetail;
