import { Button, Modal, Alert } from 'react-bootstrap';
import { Cart3 } from 'react-bootstrap-icons';
import './howToBuy.css';

const HowToBuy = (props: any) => {

    const handleClose = () => props.setShowModal(false);

    return (
        <>
            {
                <Modal size="lg" show={props.showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="light">
                            <Alert.Heading>Pasos para realizar tu compra:</Alert.Heading>
                            <p><span>&#10003;</span> Busca el producto que desees comprar</p>
                            <p><span>&#10003;</span> Presiona el botón <Button variant="custom" size="sm" disabled>Agregar al carrito</Button> y selecciona la cantidad deseada</p>
                            <p><span>&#10003;</span> Presiona sobre el ícono del carrito de compras <Cart3 scale="2" size={18} /></p>
                            <p><span>&#10003;</span> Edita la cantidad de productos que desees y presiona en <Button variant="custom" size="sm" disabled>REALIZAR PEDIDO</Button></p>
                            <p><span>&#10003;</span> A continuación, ingresa la dirección de despacho y presiona en <Button variant="custom" size="sm" disabled>INGRESAR DESPACHO</Button></p>
                            <p><span>&#10003;</span> Verifica la información final y presiona en <Button variant="custom" size="sm" disabled>CONFIRMAR Y PAGAR</Button></p>
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default HowToBuy;