export class TransactionDto {
    public amount: number;
    public buyOrder: string;
    public state: number;
    public name: string;
    public email: string;
    public date: string;
    public time: string;
    public phone: string;
    public region: string;
    public commune: string;
    public address: string;

    constructor(
        amount: number,
        buyOrder: string,
        state: number,
        name: string,
        email: string,
        date: string,
        time: string,
        phone: string,
        region: string,
        commune: string,
        address: string) {
        this.amount = amount;
        this.buyOrder = buyOrder;
        this.state = state;
        this.name = name;
        this.email = email;
        this.date = date;
        this.time = time;
        this.phone = phone;
        this.region = region;
        this.commune = commune;
        this.address = address;
    }
}