import './Header.css';
import { Navbar, Nav } from 'react-bootstrap';
import AboutModal from '../../views/modal/about/about.modal';
import { useState } from 'react';
import HowToBuy from '../../views/modal/howToBuy/howToBuy.modal';
import ContactModal from '../../views/modal/contact/contact.modal';
import { Cart3 } from 'react-bootstrap-icons';
import CartModal from '../../views/modal/cart/cart.modal';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/store';

const Header = (props: any) => {
  document.documentElement.style.setProperty('--color-header', `${props.design.colorHeader}`);

  const [showCartModal, setShowCartModal] = useState(false);
    const handleShowCartModal = () => setShowCartModal(true);

    const { itemList } = useSelector(
        (store: AppState) => store.cart
    )

    const itemQuantity = itemList.reduce(function (previous: any, current: any) {
        return previous + current.cantidad;
    }, 0);

  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showHowToBuyModal, setShowHowToBuyModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const handleShow = (modal: string) => {
    switch (modal) {
      case "about":
        setShowAboutModal(true);
        break;
      case "how-to-buy":
        setShowHowToBuyModal(true);
        break;
      case "contact":
        setShowContactModal(true);
        break;
    }
  }

  return (
    <>
      <Navbar id="header" className="d-flex justify-content-between" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {props.design.logo !== '' ? (
            <Navbar.Brand href="/" className="logoTienda">
              <img
                alt="Empresa"
                src={props.design.logo}
                className="p-2 bd-highlight img-logo"
              />

              </Navbar.Brand>
              ) :
              (
                <Navbar.Brand className="nombretienda" href="#">
                  <h3 className="title">
                  {props.design.nombreTienda}
                  </h3>
                </Navbar.Brand>
              )
            }
        <div className="size-cart">
              <Navbar.Brand href="#home" onClick={handleShowCartModal}>
                <Cart3 scale="2" size={30} />
                  <span className="badge badge-danger">{itemQuantity}</span>
              </Navbar.Brand>
            </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="align" onSelect={(eventKey) => handleShow(eventKey || '')}>
            <Nav.Link eventKey="about"><strong className="menu">Quienes somos</strong></Nav.Link>
            <Nav.Link eventKey="how-to-buy"><strong className="menu">Cómo comprar</strong></Nav.Link>
            <Nav.Link eventKey="contact"><strong className="menu">Contacta con nosotros</strong></Nav.Link>
          </Nav>
        </Navbar.Collapse>
          
            
                
      </Navbar>
      <CartModal showModal={showCartModal} setShowModal={setShowCartModal}></CartModal>
      <AboutModal showModal={showAboutModal} setShowModal={setShowAboutModal}></AboutModal>
      <HowToBuy showModal={showHowToBuyModal} setShowModal={setShowHowToBuyModal}></HowToBuy>
      <ContactModal showModal={showContactModal} setShowModal={setShowContactModal}></ContactModal>
    </>
  );
}

export default Header;
