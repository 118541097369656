import { Button, Modal, Table } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { ItemDto } from '../../../models/dataTransferObjects/item.dto';
import { ProductDto } from '../../../models/dataTransferObjects/product.dto';
import { AppState } from '../../../redux/store';
import { AddItemToCart, RemoveItemFromCart } from '../../../manager/cart.manager';
import { useState } from 'react';
import DeliveryModal from '../../../views/modal/delivery/delivery.modal';
import { CurrencyFormat } from "../../../utils/currencyFormat";
import './cart.modal.css';

const CartModal = (props: any) => {
  const dispatch = useDispatch();
  const { itemList } = useSelector(
    (store: AppState) => store.cart
  )

  const totalPrice = itemList.reduce(function (previous: any, current: ItemDto) {
    return previous + (current.cantidad * current.producto.precio);
  }, 0);

  const handleClose = () => {
    console.log('handleClose');
    props.setShowModal(false);
  }

  const addToCart = async (product: ProductDto) => {
    await dispatch(AddItemToCart(product))
  }
  const removeFromCart = async (product: ProductDto) => {
    await dispatch(RemoveItemFromCart(product))
  }

  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const handleDeliveryShow = () => {
    setShowDeliveryModal(true);
    handleClose();
  }

  return (
    <>
      {
        <Modal size="lg" show={props.showModal} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Mi carrito</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th className="col-4">Producto</th>
                  <th className="col-2 text-center">Cantidad</th>
                  <th className="col-4 text-center">Precio</th>
                </tr>
              </thead>
              <tbody>
                {
                  itemList.map((item: ItemDto, index: number) => (
                    <tr key={index}>
                      <td className="col-4">{item.producto.nombre}</td>
                      <td className="col-2 size-un text-center">
                        <Button variant="primary btn-sm float-left" onClick={() => removeFromCart(item.producto)}>-</Button>
                        <span>{item.cantidad}</span>
                        <Button variant="primary btn-sm float-right" onClick={() => addToCart(item.producto)}>+</Button>
                      </td>
                      <td key={index} className="col-4 text-center">{CurrencyFormat(item.producto.precio)}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td className="col-4"></td>
                  <td className="col-2 text-right">Total:</td>
                  <td className="col-4 text-center">
                    <span><b>{CurrencyFormat(totalPrice)}</b></span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="success" disabled={itemList.length <= 0} onClick={handleDeliveryShow}>
              Realizar Pedido
            </Button>
          </Modal.Footer>
        </Modal>
      }
      <DeliveryModal showModal={showDeliveryModal} setShowModal={setShowDeliveryModal}></DeliveryModal>
    </>
  );
}

export default CartModal;