import Product from '../product/Product';
import Category from '../category/Category';
import { Container, Row, CardGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ProductService } from '../../services/product.service';
import { AppState } from '../../redux/store';
import { useSelector } from 'react-redux';
import './Products.css';
import { ProductPaginationDto } from '../../models/dataTransferObjects/productPagination.dto';
import { PaginationDto } from '../../models/dataTransferObjects/pagination.dto';
import { Pagination } from '@material-ui/lab';
import { PulseLoader } from "react-spinners";

const Products = () => {
  const [productPagination, setProductPagination] = useState<ProductPaginationDto>(new ProductPaginationDto([], new PaginationDto(1, 0, 0, 0, 0)));
  const { idCategory } = useSelector(
    (store: AppState) => store.category
  )
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setPage(1);
    const getProducts = async () => {
      setLoading(true);
      cleanList();
      const productService = new ProductService();
      let idStore = (document.getElementById("id-tienda") as HTMLInputElement).value;
      let prodPagination = await productService.GetProducts(idStore, idCategory, 1);
      setProductPagination(prodPagination);
      let value = (prodPagination.paginationDto.totalRows % prodPagination.paginationDto.perPage) >= 1 ? 1 : 0;
      setPages(Math.trunc(prodPagination.paginationDto.totalRows / prodPagination.paginationDto.perPage) + value);
      setLoading(false);
    }
    getProducts();
  }, [idCategory]);

  const getProductsPerPage = async (pag: number) => {
    setLoading(true);
    cleanList();
    const productService = new ProductService();
    let idStore = (document.getElementById("id-tienda") as HTMLInputElement).value;
    setProductPagination(await productService.GetProducts(idStore, idCategory, pag))
    setLoading(false);
  }

  const cleanList = async() => {
    setProductPagination(new ProductPaginationDto([], new PaginationDto(1, 0, 0, 0, 0)));
  }

  const handleChange = async (event: any, value: any) => {
    setPage(value);
    getProductsPerPage(value);
  }
  return (
    <>
      <Container className="productsContainer">
        <div className="title-align">
          <h4 className="product-title">Conoce nuestros productos</h4>
        </div>

        <Category />
        <Pagination count={pages} page={page} onChange={handleChange} />
        <Row>
          <div className="cards align-items-stretch">
            {loading ? <div className="loading">
              <PulseLoader size={50} color={"#DABBB4"} loading={loading} />
            </div> : null}
            <CardGroup bsPrefix="card-deck" className="alignCard">
              {
                productPagination.productDto.map((item, index) =>
                  <Product {...item} key={index} />
                )
              }

            </CardGroup>

          </div>

        </Row>
        <Pagination count={pages} page={page} onChange={handleChange} />
      </Container>
    </>
  );
}

export default Products;
