import { Button, Modal, Alert } from 'react-bootstrap';
import './about.css';

const AboutModal = (props: any) => {

    const handleClose = () => props.setShowModal(false);

    return (
        <>
            {
                <Modal size="lg" show={props.showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img
                                alt="Empresa"
                                src='https://kchilupipets.cl/img/perro3.png'
                                height="250px"
                                className="p-2 bd-highlight imagen-about"
                            />
                        </div>
                        <Alert variant="light">
                            <Alert.Heading className="text-center">¡Bienvenidos!</Alert.Heading>
                            <p className="text-justify">
                                Somos una tienda online dedicada a la venta de alimentos y accesorios
                                para mascotas por mayor y al detalle. Aquí podrás encontrar los mejores
                                productos para el regalón de la casa.
                            </p>
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default AboutModal;