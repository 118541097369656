import { useEffect, useState, Fragment } from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Banner from "../components/banner/Banner";
import Products from "../components/products/Products";

import { DesignService } from "../services/design.service";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductDetail from "./productDetail/product-detail.view";
import PaymentResult from "./paymentResult/payment-result.view";

const Store = () => {
  const [design, setDesign] = useState({});
  let idStore = (document.getElementById("id-tienda") as HTMLInputElement)
    .value;

  useEffect(() => {
    getDesign();
  }, []);

  const getDesign = async () => {
    const designService = new DesignService();
    setDesign(await designService.GetDesing(idStore));
  };

  return (
    <Fragment>
      <Header design={design}></Header>

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner design={design}></Banner>
                <Products></Products>
              </>
            }
          />
          <Route path="/producto/:id" element={<ProductDetail />} />

          <Route path="/pago/:id" element={<PaymentResult />} />
        </Routes>
      </BrowserRouter>

      <Footer></Footer>
    </Fragment>
  );
};

export default Store;
