export class ProductDto {
    public id: number;
    public imagen: string;
    public nombre: string;
    public marca: string;
    public precio: number;

    constructor(id: number, imagen: string, nombre: string, marca: string, precio: number) {
        this.id = id;
        this.imagen = imagen;
        this.nombre = nombre;
        this.marca = marca;
        this.precio = precio;
    }
}