import './Category.css';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CategoryDto } from '../../models/dataTransferObjects/category.dto';
import { ProductService } from '../../services/product.service';
import { useDispatch } from 'react-redux';
import { SetCategory } from '../../redux/actions/category.action';

const Category = () => {
    const dispatch = useDispatch();
    const [categoryLst, setCategory] = useState<CategoryDto[]>([]);
    const [value, setValue] = useState(0);


    const handleChange = async (val: any) => {
        setValue(val);
        await dispatch(SetCategory(val));
    }

    useEffect(() => {
        getCategory()
    }, []);

    const getCategory = async () => {
        const productService = new ProductService();
        let idStore = (document.getElementById("id-tienda") as HTMLInputElement).value
        setCategory(await productService.GetCategory(idStore))
    }

    return (
        <>
            <div className="text-center">
                <ToggleButtonGroup type="radio" name="options" className="mb-2" value={value} onChange={handleChange}>

                    <ToggleButton value={0} variant="light" size="lg" className="color-text text-category">TODOS</ToggleButton>
                    {
                        categoryLst.map((item: any, index) =>
                            <ToggleButton key={index} value={item.id} variant="light" size="lg" className="color-text text-category">{item.nombre.toUpperCase()}</ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
            </div>
        </>
    );
}
export default Category;