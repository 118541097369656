import { DesignDto } from '../models/dataTransferObjects/design.dto';

export class DesignMapper {
    public static toDto(operationResult: any): DesignDto {
        const dto = new DesignDto(
            operationResult.resultado.UrlBanner,
            operationResult.resultado.NombreTienda,
            operationResult.resultado.Logo,
            operationResult.resultado.MensajeBienvenida,
            operationResult.resultado.ColorHeader,
            operationResult.resultado.UrlsBanner
        );
        return dto;
    }
}
