import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AppState } from "../../redux/store";
import { ProductDto } from "../../models/dataTransferObjects/product.dto";
// import Product from "../../components/product/Product";
import { ProductService } from "../../services/product.service";
import { CurrencyFormat } from "../../utils/currencyFormat";

import "./payment-result-view.css";
import { TransactionDto } from "../../models/dataTransferObjects/transaction.dto";

type TransactionState = {
  transaction: TransactionDto | undefined;
  state: "loading" | "ready" | "error";
};

const PaymentResult = (props: any) => {
  // const dispatch = useDispatch();
  // const { itemList } = useSelector((store: AppState) => store.cart);
  const { id } = useParams();
  const [state, setState] = useState<TransactionState>({
    transaction: undefined,
    state: "loading",
  });
  // let idStore = (document.getElementById("id-tienda") as HTMLInputElement)
  //   .value;

  useEffect(() => {
    console.log("==> id: ", id);
    const productService = new ProductService();
    productService
      .GetTransaction(id!)
      .then((resp) => {
        console.log("RESPUESTA: ", resp);
        setState({ transaction: resp, state: "ready" });
      })
      .catch((err) => {
        setState({ transaction: undefined, state: "error" });
      });
  }, []);

  if (state.state === "error") {
    return (
      <div className="mt-4 mb-4 text-center">
        <h3>El producto no fue encontrado en el catálogo</h3>
      </div>
    );
  }

  // const item = GetExistingItemFromCart(state.product, itemList);
  // const cantidad = item ? item.cantidad : 0;
  // const product = new ProductDto(
  //   state.product?.id ?? 0,
  //   state.product?.imagen ?? "",
  //   state.product?.nombre ?? "",
  //   state.product?.marca ?? "",
  //   state.product?.precio ?? 0
  // );
  // const item = GetExistingItemFromCart(product, itemList);
  // const cantidad = item ? item.cantidad : 0;
  // const addToCart = async () => {
  //   if (state.product) {
  //     console.log("productDto: ", state.product);

  //     await dispatch(AddItemToCart(state.product));
  //   }
  // };
  // const removeFromCart = async () => {
  //   if (state.product) {
  //     await dispatch(RemoveItemFromCart(state.product));
  //   }
  // };

  return (
    <>
      {/* {state.state === "loading" ? (
        <div className="loading">
          <PulseLoader size={50} color={"#DABBB4"} />
        </div>
      ) : null} */}
      <div className="container-payment-result">
        <Container>
          <Row className="justify-content-md-center">
            <h3>Comprobante de pago N°{state.transaction?.buyOrder}</h3>
          </Row>
          <br />
          <Row className="justify-content-md-center">
            <h6>
              Estimado {state.transaction?.name}, hemos procesado su pago el cuál se
              encuentra en estado:{" "}
              {state.transaction?.state === 2 ? "En Proceso" : "Exitoso"}. 
              <br />
              A continuación el detalle del pago realizado:
            </h6>
          </Row>
          <br />
          <Row>
            <Col xs lg="2"></Col>
            <Col xs lg="8">
              <Table responsive>
                <tbody>
                  <tr>
                    <td className="text-left">Nombre</td>
                    <td className="text-right">{state.transaction?.name}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Correo Electrónico</td>
                    <td className="text-right">{state.transaction?.email}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Fecha</td>
                    <td className="text-right">{state.transaction?.date}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Monto Pagado</td>
                    <td className="text-right">
                      {CurrencyFormat(
                        state.transaction ? state.transaction.amount : 0
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Teléfono</td>
                    <td className="text-right">{state.transaction?.phone}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Región</td>
                    <td className="text-right">{state.transaction?.region}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Comuna</td>
                    <td className="text-right">{state.transaction?.commune}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Dirección</td>
                    <td className="text-right">{state.transaction?.address}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs lg="2"></Col>
          </Row>
        </Container>
      </div>
      <br />
      <div className="text-center">
        <NavLink to="/">Volver</NavLink>
      </div>
    </>
  );
};

export default PaymentResult;
