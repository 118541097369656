import { PaginationDto } from "../models/dataTransferObjects/pagination.dto";
import { ProductPaginationDto } from "../models/dataTransferObjects/productPagination.dto";
import { ProductMapper } from "./product.mapper";

export class ProductPaginationMapper {
    public static toDto(operationResult: any): ProductPaginationDto {
        let product = ProductMapper.toDto(operationResult);
        let pagination = new PaginationDto(operationResult.paginacion.paginaActual,
            operationResult.paginacion.porPagina,
            operationResult.paginacion.totalRows,
            operationResult.paginacion.previous,
            operationResult.paginacion.next);
        return new ProductPaginationDto(product, pagination);
    }
}